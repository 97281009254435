import { required } from 'vuelidate/lib/validators'
import { strToId } from '@/utils/formatters'
import formUtils from '@/utils/mixins/formUtils'

export default {
  mixins: [formUtils],
  props: {
    item: { type: Object, required: true },
    isNewItem: { type: Boolean, default: false },
    index: { type: Number, required: true },
    namesItems: { type: Array, default: () => [] },
  },
  data: () => ({
    clicked: false,
    currentElementPosition: {},
    form: { name: null },
  }),
  validations() {
    return { form: { name: { required, isUnique: value => !this.namesItems.map(v => strToId(v)).includes(strToId(value)) } } }
  },
  computed: {
    stylesDialog() {
      return {
        editZone: {
          width: `${this.currentElementPosition.width}px`,
          top: `${this.currentElementPosition?.top}px`,
          left: `${this.currentElementPosition?.left}px`,
        },
        deleteButton: {
          top: `${this.currentElementPosition.top - this.currentElementPosition.height - 32}px`,
          left: `${this.currentElementPosition.left + this.currentElementPosition.width + 16}px`,
        },
        saveButton: {
          width: `${this.currentElementPosition.width}px`,
          maxWidth: `${['xs'].includes(this.$vuetify.breakpoint.name) ? this.currentElementPosition.width : 241}px`,
          top: `${this.currentElementPosition.top + 16}px`,
          left: `${this.currentElementPosition.left - (['xs', 'sm'].includes(this.$vuetify.breakpoint.name) ? 1 : 60)}px`,
        },
      }
    },
  },
  created() {
    this.form.name = this.item.name
    this.clicked = this.isNewItem
  },
  methods: {
    save() {
      if (!this.isFormValid()) return
      const { form: { name }, isNewItem } = this
      const id = isNewItem ? strToId(name) : this.item.id
      this.$emit('onSave', { id, name, isNewItem })
      this.clicked = false
    },
  },
}
